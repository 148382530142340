import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Filtering from "../components/filtering";
import FeaturedPost from "../components/featured-post";

export const query = graphql`
  query EventsIndexQuery {
    events: allSanityEvents(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: startDate, order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          _type
          tags {
            value
          }
          path
          location
          tileDescription
          id
          startDate
          endDate
          image {
            asset {
              _id
              altText
            }
          }
        }
      }
    }
    tags: allSanityEvents {
      distinct(field: tags___value)
    }
    locations: allSanityEvents {
      distinct(field: location)
    }
    page: allSanityEventsPage(filter: { slug: { current: { eq: "events" } } }) {
      edges {
        node {
          title
          pageSEO {
            metaDescription
            metaTitle
            metaImage {
              asset {
                _id
                altText
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
          accordionModule {
            _key
            _type
            heading
            items {
              heading
              _rawBody(resolveReferences: { maxDepth: 8 })
            }
            buttonText
            buttonLink {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  parentPage {
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
          }
          featuredEvent {
            title
            _type
            path
            tileDescription
            slug {
              current
            }
            tags {
              value
            }
            startDate
            endDate
            dates {
              date
              time
            }
            image {
              asset {
                altText
                _id
              }
            }
          }
        }
      }
    }
  }
`;

class Events extends React.Component {
  render() {
    const events = this.props.data.events.edges;
    const tags = this.props.data.tags.distinct;
    const locations = this.props.data.locations.distinct;
    const page = this.props.data.page.edges[0].node;
    return (
      <Layout className="events-index">
        <SEO title={page.title} seo={page.pageSEO} />
        <FeaturedPost
          featuredPost={
            page.featuredEvent
              ? page.featuredEvent
              : events[events.length - 1].node
          }
        />
        <Filtering
          data={events}
          tags={tags}
          type="events"
          locations={locations}
        />
      </Layout>
    );
  }
}

export default Events;
